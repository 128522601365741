module.exports = [{
      plugin: require('/tmp/89a06d9/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/89a06d9/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/tmp/89a06d9/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.7","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-unwrap-images"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1152,"linkImagesToOriginal":false,"quality":75,"withWebp":false}},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files"}],"remarkPlugins":[null],"defaultLayouts":{"default":"/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js","home":"/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Homepage.js"}},
    },{
      plugin: require('/tmp/89a06d9/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/89a06d9/node_modules/gatsby-theme-carbon/gatsby-browser.js'),
      options: {"plugins":[],"mdxExtensions":[".mdx"],"repository":{"baseUrl":"https://github.com/carbon-design-system/carbon-www","subDirectory":""}},
    },{
      plugin: require('/tmp/89a06d9/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
